import React from "react"
import PropTypes from "prop-types"
import {Columns, Column} from 'bloomer';
import {ContainerHeader, ContLogo, GlobalStyleHeader, LinkGatsby} from './styles';
import {LogoPrimary} from '../Logo/logoPrimary';
import MenuDesk from "../Menu";
import {NavFullScreenOverlay} from "../NavFullScreenOverlay";
import Language from "../Language";

const Header = ({ siteTitle }) => (
  <header>
    <GlobalStyleHeader/>
    <ContainerHeader>
      <Columns
        className="columns-responsive full-height"
        isMultiline={true}
      >

        <Column
          className="no-display-mobile"
          isSize={{mobile: 0, tablet: 0, desktop: 1, widescreen: 1}}>&nbsp;</Column>
        <Column
          className={'full-height padding-top-no'}
          isSize={{mobile: 12, tablet: 12, desktop: 2, widescreen: 3}}
        >
          <ContLogo className={'ajuste-logo-movil___'}>
            <LinkGatsby to={'/'} target="_self">
              <LogoPrimary/>
            </LinkGatsby>
          </ContLogo>

          <NavFullScreenOverlay/>

        </Column>

        <Column
          className="no-display-mobile"
          isSize={{mobile: 0, tablet: 12, desktop: 7, widescreen: 6}}
        >
          <MenuDesk
            itemsMenuDesk={
              [
                {
                  itemMenu: 'Inicio',
                  linkTo: ''
                },{
                  itemMenu: 'Desarrollos',
                  linkTo: 'desarrollos'
                },{
                  itemMenu: 'Life Style',
                  linkTo: 'lifestyle'
                },{
                  itemMenu: 'Hablemos',
                  linkTo: 'hablemos'
                }
              ]
            }
          />
        </Column>

        <Column
          isSize={{mobile: 0, tablet: 0, desktop: 1, widescreen: 1}}
        >
          <Language/>
        </Column>
        <Column
          className="no-display-mobile"
          isSize={{mobile: 0, tablet: 0, desktop: 1, widescreen: 1}}>&nbsp;</Column>

      </Columns>
    </ContainerHeader>
  </header>
)

Header.propTypes = {
  siteTitle: PropTypes.string
}

export default Header
