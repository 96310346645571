import styled from "styled-components";
import {GlobalColors} from "../../styles/GlobalStyles";

export const ContIcoMenuCloseResponsive = styled.div`
  
  position: absolute;
  top: 12px;
  right: 25px;
  
  width: 50px;
  height: 50px;
  display: none;
  align-items: center;
  justify-content: center;
  
  font-size: 25px;
  color: ${GlobalColors.colorPrimary};
  
  cursor: pointer;
  
  @media (max-width: 768px){
    display: flex !important;
    //right: 15px !important;
  }
  @media (max-width: 1023px){
    display: flex !important;
    //right: 25px;
  }
  
`
