import React from 'react'
import { ContIcoMenuResponsive } from './styles-ico-menu'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faBars } from '@fortawesome/free-solid-svg-icons'

export const IcoMenu = ({props, onClick}) => {

  return(
    <ContIcoMenuResponsive onClick={onClick}>
      <FontAwesomeIcon icon={faBars} />
    </ContIcoMenuResponsive>
  )
}
