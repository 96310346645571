import React, {  Fragment, useEffect } from 'react';
import { Columns, Column } from 'bloomer';
import {LogoPrimary} from "../Logo/logoPrimary";
import { IcoMenu } from "./ico-menu";
import { IcoCloseMenu } from "./ico-close-menu";
import {GlobalColors} from "../../styles/GlobalStyles";
import { ContLogo } from "../Header/styles";
import {GlobalStyleNavFullScreen, ContainerNavFullScreen, Nav, Link} from "./styles";


export const NavFullScreenOverlay = ({nameColor}) => {

  let handleMenuFullScreen = function(bolShowMenuResponsive) {
    let el;
    let cls;

    setTimeout(
      function() {
        el = document.getElementById('nav-fullscreen');
        el.classList.remove("nav-fullscreen-show");
        el.classList.remove("nav-fullscreen-hidden");

        cls = bolShowMenuResponsive ? "nav-fullscreen-show" : "nav-fullscreen-hidden";
        el.classList.add(cls);

        bolShowMenuResponsive ? document.getElementById("nav-fullscreen").style.height = "100%" : document.getElementById("nav-fullscreen").style.height = "0%";
      },
      100
    );
  }

  useEffect(() => {
    function handleResize() {
      if(window.innerWidth >= 1024){
        document.getElementById("nav-fullscreen").style.height = "0%";
      }
      //console.log('resized to: ', window.innerWidth, 'x', window.innerHeight)
    }
    window.addEventListener('resize', handleResize)
  });

  return (
    <Fragment>
      <GlobalStyleNavFullScreen/>

      <IcoMenu
        onClick={() => handleMenuFullScreen(true)}
      />

      <div id="nav-fullscreen" className={`nav-fullscreen-hidden`}>
        <ContainerNavFullScreen className="cont-nav-fullscreen">
          <Columns className="columns-responsive">
            <Column className="flex-center" isSize={{mobile: 12, tablet: 12, desktop: 0, widescreen: 0 }}>
              <ContLogo>
                <LogoPrimary/>
              </ContLogo>

              <IcoCloseMenu
                onClick={() => handleMenuFullScreen(false)}
              />

            </Column>
          </Columns>
        </ContainerNavFullScreen>
        <ContainerNavFullScreen className="cont-nav-fullscreen">
          <Columns className="columns-responsive">
            <Column isSize={{mobile: 6, tablet: 6, desktop: 6, widescreen: 6 }}>
              <Nav>
                <Link
                  color={(nameColor === 'dark') ? GlobalColors.colorSecondary : GlobalColors.colorPrimary}
                  to="/"
                  onClick={() => handleMenuFullScreen(false)}
                >
                  Inicio
                </Link>
                <Link
                  color={(nameColor === 'dark') ? GlobalColors.colorSecondary : GlobalColors.colorPrimary}
                  to="/desarrollos"
                  onClick={() => handleMenuFullScreen(false)}
                >
                  Desarrollos
                </Link>
                <Link
                  color={(nameColor === 'dark') ? GlobalColors.colorSecondary : GlobalColors.colorPrimary}
                  to="/lifestyle"
                  onClick={() => handleMenuFullScreen(false)}
                >
                  Life Style
                </Link>
                <Link
                  color={(nameColor === 'dark') ? GlobalColors.colorSecondary : GlobalColors.colorPrimary}
                  to="/hablemos"
                  onClick={() => handleMenuFullScreen(false)}
                >
                  Hablemos
                </Link>
              </Nav>
            </Column>
            <Column isSize={{mobile: 6, tablet: 6, desktop: 6, widescreen: 6 }}>

            </Column>
          </Columns>
        </ContainerNavFullScreen>
      </div>
    </Fragment>
  )
}
