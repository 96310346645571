import styled from 'styled-components'
import { Link as GatsbyLink } from "gatsby";
import {GlobalColors} from "../../styles/GlobalStyles";

export const LinkGatsby = styled(GatsbyLink)`
  /* Display & Box Model */
  display: flex;
  justify-content: center;
  flex-flow: wrap;
  width: 100%;
  height: 100%;
  padding: 20px 5px;

  /* Typografhy */
  text-decoration: none;
  font: 20px HelveticaNeue;
  color: ${GlobalColors.colorPrimary};
  
  &:hover{
    color: ${GlobalColors.colorLinkHover};
  }
  
  &[aria-current]{
    color: ${GlobalColors.colorLinkHover};
  }
  
  @media(min-width: 300px) and (max-width: 768px){
    font-size: 20px;
  }
  @media(min-width: 769px) and (max-width: 1023px){
    font-size: 20px;
  }
  @media(min-width: 1024px) and (max-width: 1215px){
    font-size: 20px;
  }
  @media(min-width: 1216px) and (max-width: 1407px){
    font-size: 20px;
  }
  @media(min-width: 1408px){
    font-size: 20px;
  }
`
