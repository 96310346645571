import styled from 'styled-components'

export const NavMenu =styled.nav`
  display: flex;
  align-items: center;
  justify-content: center;
`

export const UlMenu = styled.ul`
  display: inline-flex;
  
`

export const LiItemMenu = styled.li`
  min-width: 100px;
  text-align: center;
`
