import React from 'react';
import {LiItemLanguage, LinkLanguage, LinkLanguageSeparator, NavLanguage, UlLanguage} from "./styles";

//const arLanguages = [ 'es', 'en' ];
const arLanguages = [
  {
    language : 'es',
    text : 'Es'
  },
  {
    language : '-',
    text : '|'
  },
  {
    language : 'en',
    text : 'En'
  }
];


const Language = () => {

  return (
    <NavLanguage>
      <UlLanguage>
        {
          arLanguages.map((lang, index) => {

            if( lang.language != '-' ){
              return(
                <LiItemLanguage key={index}>
                  <LinkLanguage to={`/${lang.language}`}>{lang.text}</LinkLanguage>
                </LiItemLanguage>
              )
            }else{
              return(
                <LiItemLanguage key={index}>
                  <LinkLanguageSeparator>
                    {lang.text}
                  </LinkLanguageSeparator>
                </LiItemLanguage>
              )
            }
          })
        }
      </UlLanguage>
    </NavLanguage>
  )
}

export default Language
