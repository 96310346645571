import * as React from "react"
import PropTypes from "prop-types"
import {LinkGatsby} from './stylesItemMenuDesk'

const ItemMenuDesk = ({itemText, linkTo}) => {

  /*
  console.log('itemText => ', itemText);
  console.log('linkTo => ', linkTo);
  */

  return (
    <LinkGatsby
      to={`/${linkTo}`}
    >
      {itemText}
    </LinkGatsby>
  )
}

ItemMenuDesk.propTypes = {
  itemText: PropTypes.node.isRequired,
  linkTo: PropTypes.node.isRequired
}

export default ItemMenuDesk
