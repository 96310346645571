import styled from "styled-components";
import {GlobalColors} from "../../styles/GlobalStyles";

export const ContIcoMenuResponsive = styled.div`
  /*position: absolute;*/
  right: 15px;
  float: right;
  
  width: 50px;
  height: 100%;
  display: none;
  align-items: center;
  justify-content: center;
  
  font-size: 25px;
  color: ${GlobalColors.colorPrimary};
  
  cursor: pointer;
  
  @media (max-width: 768px){
    display: flex !important;
    right: 15px !important;
  }
  @media (max-width: 1023px){
    display: flex !important;
    right: 25px;
  }
  
`

export const Svg = styled.svg`
  
`
