import styled, {createGlobalStyle} from 'styled-components'
import { Link as GatsbyLink } from "gatsby";
import {GlobalColors} from "../../styles/GlobalStyles";

export const GlobalStyles = createGlobalStyle`
  .line{
    
  }
`

export const NavLanguage =styled.nav`
  display: flex;
  align-items: center;
  justify-content: flex-end;
  
  @media(min-width: 300px) and (max-width: 767px){
    display: none;
  }
  @media(min-width: 768px) and (max-width: 1023px){
    display: none;
  }
  @media(min-width: 1024px) and (max-width: 1215px){
    display: flex;
  }
  @media(min-width: 1216px) and (max-width: 1407px){
    display: flex;
  }
  @media(min-width: 1408px){
    display: flex;
  }
`

export const UlLanguage = styled.ul`
  display: inline-flex;
`
export const LiItemLanguage = styled.li`
  text-align: center;
`
export const LinkLanguage = styled(GatsbyLink)`
  display: flex;
  justify-content: center;
  flex-flow: wrap;
  width: auto;
  height: 100%;
  padding: 20px 5px;

  /* Typografhy */
  text-transform: capitalize;
  text-decoration: none;
  font: 20px HelveticaNeue;
  color: ${GlobalColors.colorPrimary};
  
  &:hover{
    color: ${GlobalColors.colorLinkHover};
  }
  
  &[aria-current]{
    color: ${GlobalColors.colorLinkHover};
  }
  
  @media(min-width: 300px) and (max-width: 768px){
    font-size: 20px;
  }
  @media(min-width: 769px) and (max-width: 1023px){
    font-size: 20px;
  }
  @media(min-width: 1024px) and (max-width: 1215px){
    font-size: 20px;
  }
  @media(min-width: 1216px) and (max-width: 1407px){
    font-size: 20px;
  }
  @media(min-width: 1408px){
    font-size: 20px;
  }
`
export const LinkLanguageSeparator = styled.div`
  display: flex;
  justify-content: center;
  flex-flow: wrap;
  width: auto;
  height: 100%;
  padding: 20px 5px;

  /* Typografhy */
  text-transform: capitalize;
  text-decoration: none;
  font: 20px HelveticaNeue;
  color: ${GlobalColors.colorPrimary};
  
  @media(min-width: 300px) and (max-width: 768px){
    font-size: 20px;
  }
  @media(min-width: 769px) and (max-width: 1023px){
    font-size: 20px;
  }
  @media(min-width: 1024px) and (max-width: 1215px){
    font-size: 20px;
  }
  @media(min-width: 1216px) and (max-width: 1407px){
    font-size: 20px;
  }
  @media(min-width: 1408px){
    font-size: 20px;
  }
`
