import React from 'react'
import { ContIcoMenuCloseResponsive } from './styles-ico-close-menu'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTimes } from '@fortawesome/free-solid-svg-icons'

export const IcoCloseMenu = ({props, onClick}) => {

  return(
    <ContIcoMenuCloseResponsive onClick={onClick}>
      <FontAwesomeIcon icon={faTimes} />
    </ContIcoMenuCloseResponsive>
  )
}
