import styled, {createGlobalStyle} from 'styled-components'
import { Container } from 'bloomer';
import { Link as GatsbyLink } from "gatsby";

export const GlobalStyleHeader = createGlobalStyle`
  @media(min-width: 300px) and (max-width: 767px){
    header{
      height: 110px;
    }
    .padding-top-no{
      padding-top: 0 !important;
    }
  }
  @media(min-width: 768px) and (max-width: 1023px){
    header{
      height: 110px;
    }
    .padding-top-no{
      padding-top: 0 !important;
    }
  }
  @media(min-width: 1024px) and (max-width: 1215px){
    
  }
  @media(min-width: 1216px) and (max-width: 1407px){
    
  }
  @media(min-width: 1408px) and (max-width: 1919px){
    
  }
  @media(min-width: 1920px){
    
  }
`

export const ContainerHeader = styled(Container)`
  position: relative;
  height: 100%;
`

export const ContLogo = styled.div`
  position: relative;
  width: 220px;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  float: left;
  padding-top: 5px !important;
  padding-left: 0px !important;
  padding-right: 0px !important;
`
export const LinkGatsby = styled(GatsbyLink)`
  /* Display & Box Model */
  display: flex;
  justify-content: center;
  flex-flow: wrap;
  width: 100%;
  height: 100%;

  /* Typografhy */
  text-decoration: none;
  color: ${props => props.color};
  
  &:hover{
    color: ${props => props.color};
  }
  
  &[aria-current]{
    color: ${props => props.color};
  }
`

export const ContNavBarMobile = styled.div`
  position: relative;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;

  @media (max-width: 1023px){
    display: none;
  }
`

export const ContRedes = styled.div`
  position: relative;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
`
